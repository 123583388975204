import { useEffect, useState } from "react";
import {
  ITeam,
  IUserLoggedIn,
  MemberTeamManagementRoleEnum,
} from "~/utils/api.interfaces.enums";

export default function useMingleRights({
  activeTeam,
  loggedInUser,
}: {
  activeTeam: ITeam | undefined;
  loggedInUser: IUserLoggedIn | undefined;
}) {
  const activeTeamPermissions = loggedInUser?.teamsPermissions.find(
    (permission) => permission.teamId === activeTeam?.id
  );

  const [hasAdminRights, setHasAdminRights] = useState<boolean | undefined>(
    activeTeamPermissions?.roles.some((i) =>
      [MemberTeamManagementRoleEnum.admin].includes(i)
    )
  );
  const [hasScheduleRights, setHasAScheduleRights] = useState<
    boolean | undefined
  >(
    activeTeamPermissions?.roles.some((i) =>
      [MemberTeamManagementRoleEnum.scheduler].includes(i)
    )
  );
  const [hasScoreRights, setHasScoreRights] = useState<boolean | undefined>(
    activeTeamPermissions?.roles.some((i) =>
      [MemberTeamManagementRoleEnum.scorekeeper].includes(i)
    )
  );
  const [hasContentCreatorRights, setContentCreatorRights] = useState<
    boolean | undefined
  >(
    activeTeamPermissions?.roles.some((i) =>
      [MemberTeamManagementRoleEnum.contentCreator].includes(i)
    )
  );

  useEffect(() => {
    const activeTeamPermissions = loggedInUser?.teamsPermissions.find(
      (permission) => permission.teamId === activeTeam?.id
    );
    setHasAdminRights(
      activeTeamPermissions?.roles.some((i) =>
        [MemberTeamManagementRoleEnum.admin].includes(i)
      )
    );
    setHasAScheduleRights(
      activeTeamPermissions?.roles.some((i) =>
        [MemberTeamManagementRoleEnum.scheduler].includes(i)
      )
    );
    setHasScoreRights(
      activeTeamPermissions?.roles.some((i) =>
        [MemberTeamManagementRoleEnum.scorekeeper].includes(i)
      )
    );
    setContentCreatorRights(
      activeTeamPermissions?.roles.some((i) =>
        [MemberTeamManagementRoleEnum.contentCreator].includes(i)
      )
    );
  }, [activeTeam, loggedInUser]);

  return {
    hasAdminRights,
    hasScheduleRights,
    hasScoreRights,
    hasContentCreatorRights,
  };
}
